export enum Gender {
  MALE= "MALE",
  FEMALE = "FEMALE"
}

export class GenderUtils {
    public static parse(gender: string): Gender {
        return Gender[gender as keyof typeof Gender];
    }

    public static format(gender: Gender): string {
        let formatted = '';
        switch (gender) {
            case Gender.MALE:
                formatted = 'Male';
                break;
            case Gender.FEMALE:
                formatted = 'Female';
                break;
        }
        return formatted;
    }
}
