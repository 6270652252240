import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appMiddleClick]'
})
export class MiddleClickDirective {

    @Output('middleClick')
    public middleClick: EventEmitter<MouseEvent>;

    constructor() {
        this.middleClick = new EventEmitter<MouseEvent>();
    }

    @HostListener('mousedown', ['$event'])
    public onMouseDown(event: MouseEvent): void {
        if (event.button === 1) {
            this.middleClick.emit(event);
        }
    }

}
