import {Component, Input, OnInit} from '@angular/core';

/**
 * Displays a user profile image or a fallback icon
 */
@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

    @Input()
    public imageBase64;

    @Input()
    public size: number = 150;

    constructor() {
    }

    ngOnInit(): void {
    }
}
