import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ComponentType} from "@angular/cdk/overlay";
import {Observable} from "rxjs";

export class Dialogs {
    public static display<T, D = any>(dialog: MatDialog, component: ComponentType<T>, config?: MatDialogConfig<D>): Observable<any> {
        let matDialogRef = dialog.open(component, config);
        return matDialogRef.afterClosed();
    }
}
