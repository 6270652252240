// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {version} from '../../../../package.json';

export const environment = {
    production: false,

    // Production
    // apiUrl: 'https://api.k-invent.com/',

    // AWS
    apiUrl: 'https://api.dev.k-invent.com/',

    // Euris
    // apiUrl: 'http://212.19.166.169/',

    // Staging
    cdnUrl: 'https://d1d602uvjn54hz.cloudfront.net',

    version: version,

    googleAnalytics: {
        measurementId: 'G-V17FZ55RFV',
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
