/**
 * Utilities for arrays
 */
export class Arrays {
    /**
     * Groups elements in an array using a grouping function and returns a Map of the array grouped by the grouping function.
     * Based on: https://stackoverflow.com/a/38327540/245495
     * @param list An array of type V.
     * @param keyGetter A Function that takes the the Array type T as an input, and returns a value of type K.
     *                  K is generally intended to be a property key of V.
     * @returns Map of the array grouped by the grouping function.
     */
    public static groupBy<T, K>(list: Array<T>, keyGetter: (input: T) => K): Map<K, T[]> {
        const map = new Map<K, T[]>();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    public static unique<T, K>(array: Array<T>, keyGetter: (input: T) => K): Array<T> {
        const grouped = this.groupBy(array, keyGetter);
        const unique = [];
        for (let entry of grouped.entries()) {
            unique.push(entry[1][0]);
        }
        return unique;
    }

    /**
     * Inserts or Removes the first occurrence of a value from an array. Does not create a new one.
     */
    public static toggleValue<T>(array: Array<T>, val: T, compareFn: (one: T, other: T) => boolean = (one, other) => one === other) {
        const index = array.findIndex(value => compareFn(value, val));

        if (index >= 0) {
            array.splice(index, 1);
        } else {
            array.push(val);
        }
    }

    public static withValue<T>(value: T | null | undefined): boolean {
        return value !== null && value !== undefined;
    }

    /**
     * Returns the unique values between two arrays
     */
    public static uniqueValues<T>(...arrays: T[][]): T[] {
        const set = new Set<T>();
        for (const array of arrays) {
            for (const t of array) {
                set.add(t);
            }
        }
        return Array.from(set);
    }
}
