import {BaseDto} from "./base.dto";
import {Gender} from "./gender";
import {OrganizationDto} from "./organization.dto";
import {AccountRole} from "./account.role";
import {SpecialityType, SpecialityTypes} from "./specialityType";

export class AccountDto extends BaseDto {
    /**
     * The token return from server at login
     */
    authToken?: string;
    email: string;
    username: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    termsOfUseVersion: string;
    specialty: string;
    termsOfUseConsentOn: number;
    organization: OrganizationDto;
    accountRole: string;
    country: string;

    public deserialize(input: any): this {
        Object.assign(this, input);
        if (input.organization) {
            this.organization = new OrganizationDto().deserialize(input.organization);
        }
        return this;
    }

    public get accountRoleType(): AccountRole {
        return AccountRole[this.accountRole];
    }

    public get specialityType(): SpecialityType {
        return SpecialityTypes.parse(this.specialty);
    }

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }
}
