import {ModuleWithProviders, NgModule} from '@angular/core';
import {AvatarComponent} from "./components/avatar/avatar.component";
import {BreadcrumbComponent} from "./components/breadcrumb/breadcrumb.component";
import {GenderSelectionComponent} from "./components/gender-selection/gender-selection.component";
import {ImageComponent} from "./components/image/image.component";
import {KinventLogoComponent} from "./components/kinvent-logo/kinvent-logo.component";
import {TextSearchComponent} from "./components/text-search/text-search.component";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatRippleModule} from "@angular/material/core";
import {CommonModule as AngularCommonModule} from "@angular/common";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {BreadcrumbModule} from "xng-breadcrumb";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatListModule} from "@angular/material/list";
import {MatCardModule} from "@angular/material/card";
import {MatRadioModule} from "@angular/material/radio";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatButtonModule} from "@angular/material/button";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatTableExporterModule} from "mat-table-exporter";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatChipsModule} from "@angular/material/chips";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {CommonBaseComponent} from "./components/common-base.component";
import {MiddleClickDirective} from "./utils/directives/middle-click.directive";
import {ModuleConfig} from "./models/moduleConfig";
import {COMMON_MODULE_CONFIG_TOKEN} from "./di";

@NgModule({
    declarations: [
        AvatarComponent,
        CommonBaseComponent,
        BreadcrumbComponent,
        GenderSelectionComponent,
        ImageComponent,
        KinventLogoComponent,
        TextSearchComponent,
        MiddleClickDirective,
    ],
    imports: [
        MatListModule,
        MatRadioModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatTableModule,
        MatSortModule,
        MatTableExporterModule,
        MatPaginatorModule,
        MatChipsModule,
        MatCardModule,
        MatProgressBarModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        FormsModule,
        MatButtonToggleModule,
        MatRippleModule,
        AngularCommonModule,
        LazyLoadImageModule,
        MatFormFieldModule,
        BreadcrumbModule,
        MatSnackBarModule,
    ],
    exports: [
        AvatarComponent,
        CommonBaseComponent,
        BreadcrumbComponent,
        GenderSelectionComponent,
        ImageComponent,
        KinventLogoComponent,
        TextSearchComponent
    ]
})
export class CommonModule {
    public static forRoot(config: ModuleConfig): ModuleWithProviders<CommonModule> {
        return {
            ngModule: CommonModule,
            providers: [{provide: COMMON_MODULE_CONFIG_TOKEN, useValue: config}]
        }
    }
}
