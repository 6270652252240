import {BaseDto} from "./base.dto";

export class SubscriptionDto extends BaseDto {
    private static readonly DAY_IN_MS = 1000 * 60 * 60 * 24;

    userCode: string;
    name: string;
    duration: number;
    subscribedOn: number;
    enabled: boolean;
    appStoreTransaction?: string;
    graceDuration?: number;
    graceStartedOn?: number;
    subscriptionPeriodUnit?: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    public get isExpired(): boolean {
        return this.duration >= 0 && this.subscribedOn + SubscriptionDto.DAY_IN_MS * this.duration! < new Date().getTime();
    }

    public get canExpire(): boolean {
        return this.duration >= 0;
    }

    public get expirationDate(): Date {
        let milliseconds = this.duration ? this.duration * SubscriptionDto.DAY_IN_MS : 0;
        return new Date(this.subscribedOn ? this.subscribedOn + milliseconds : milliseconds);
    }

}
