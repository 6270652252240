<div class="component">
    <img *ngIf="imageBase64; else icon"
         class="rounded-circle"
         [ngStyle]="{'width': size + 'px', 'height': size + 'px'}"
         [src]="'data:image/jpg;base64,' + imageBase64"
         alt="Avatar"/>
    <ng-template #icon>
        <img src="./assets/icons/ic_account_circle.png" [ngStyle]="{'width': size + 'px', 'height': size + 'px'}" alt="Avatart"/>
    </ng-template>
</div>
