import {Injectable} from '@angular/core';
import {saveAs} from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class CsvService {
    protected static DELIMITER = ',';

    constructor() {
    }

    public downloadCsv(lines: string[][], filename: string): void {
        const data = lines
            .map(lineData => lineData.join(CsvService.DELIMITER))
            .join('\r\n');

        const blob = new Blob([data], {type: 'text/csv'});
        saveAs(blob, `${filename}.csv`);
    }
}
