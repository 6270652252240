import {LogPublisher} from "./logPublisher";
import {LogEntry} from "./logEntry";

export class ConsoleLogPublisher implements LogPublisher {
    private readonly calleeStyle: string;
    private readonly messageStyle: string;

    constructor() {
        this.calleeStyle = 'color: gray';
        this.messageStyle = 'color: black';
    }

    public debug(entry: LogEntry): void {
        console.debug(`%c${entry.callee} %c${entry.message}`, 'color: gray', 'color: black');
    }

    public info(entry: LogEntry): void {
        console.info(`%c${entry.callee} %c${entry.message}`, 'color: gray', 'color: black');
    }

    public warn(entry: LogEntry): void {
        console.warn(`%c${entry.callee} %c${entry.message}`, 'color: gray', 'color: black');
    }

    public error(entry: LogEntry): void {
        console.error(`%c[ERROR] %c${entry.callee} %c${entry.message}`, 'color:red', 'color: gray', 'color: black');
    }

    public fatal(entry: LogEntry): void {
        console.error(`%c[FATAL] %c${entry.callee} %c${entry.message}`, 'color:red', 'color: gray', 'color: black');
    }
}
