import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {RxUtils} from "../../utils/RxUtils";
import {takeUntil} from "rxjs/operators";
import {CommonBaseComponent} from "../common-base.component";

/**
 * Loads an image, by default lazily. Supports urls, asset names and blobs.
 * When printing consider using non-lazy.
 */
@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent extends CommonBaseComponent implements OnInit {
    public defaultImage = './assets/kinvent_logo_dimmed_500x500.png';

    @Input()
    public image: string;

    // Whether or not to use the default/fallback image
    @Input()
    public fallback = true;

    // <img alt> tag
    @Input()
    public description: string;

    @Input()
    public lazily: boolean = true;

    @ViewChild('loadContainer', {static: true})
    loadContainer: ElementRef<HTMLImageElement>;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!this.fallback) {
            this.defaultImage = '';
        } else if (!this.lazily && this.fallback) {
            RxUtils.onImageLoad(this.loadContainer.nativeElement)
                .pipe(takeUntil(this.destroySubject))
                .subscribe(_loaded => {
                    // Nothing to do, image is already there and will be loaded
                }, _error => {
                    this.image = this.defaultImage;
                })
        }
    }
}
