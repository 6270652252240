import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {KeyValue} from "../../utils/KeyValue";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {Gender, GenderUtils} from "../../dtos/gender";
import {CommonBaseComponent} from "../common-base.component";

@Component({
    selector: 'app-gender-selection',
    templateUrl: './gender-selection.component.html',
    styleUrls: ['./gender-selection.component.scss']
})
export class GenderSelectionComponent extends CommonBaseComponent implements OnInit {
    @Output()
    public genderSelected: EventEmitter<Gender>;

    public genders: KeyValue<Gender, string>[];

    constructor() {
        super();

        this.genderSelected = new EventEmitter<Gender>();
    }

    ngOnInit(): void {
        this.genders = [];
        this.genders.push(KeyValue.of(Gender.MALE, GenderUtils.format(Gender.MALE)));
        this.genders.push(KeyValue.of(Gender.FEMALE, GenderUtils.format(Gender.FEMALE)));
    }

    public onChange(event: MatButtonToggleChange): void {
        const toggle = event.source;
        let value: Gender | undefined;
        if (toggle) {
            let group = toggle.buttonToggleGroup;
            if (event.value.some(item => item == toggle.value)) {
                group.value = [toggle.value];
            }

            value = group.value[0];
        }
        this.genderSelected.emit(value);
    }
}
