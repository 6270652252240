export class ModuleConfig {
    public apiUrl: string;
    public cdnUrl: string;

    public static for(apiUrl: string, cdnUrl: string): ModuleConfig {
        const instance = new ModuleConfig();
        instance.apiUrl = apiUrl;
        instance.cdnUrl = cdnUrl;
        return instance;
    }
}
