import {BaseDto} from "./base.dto";

export class OrganizationDto extends BaseDto {
    name?: string;
    address?: string;
    phone?: string;
    email?: string;
    logo?: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
