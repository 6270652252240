export enum SpecialityType {
    UNKNOWN,
    PHYSIOTHERAPIST,
    CHIROPRACTOR,
    CHIROPODIST,
    DOCTOR,
    OSTEOPATH,
    REHABILITATION_DOCTOR,
    SPORTS_MEDICINE_DOCTOR,
    ORTHOPEDIC_SURGEON,
    PODOLOGIST,
    FITNESS_TRAINER,
    SPORTS_COACH,
    STUDENT,
    OTHER
}

export class SpecialityTypes {
    public static parse(speciality: string): SpecialityType {
        return SpecialityType[speciality as keyof typeof SpecialityType];
    }

    public static format(specialityType?: SpecialityType): string {
        let formatted;
        switch (specialityType) {
            case SpecialityType.PHYSIOTHERAPIST:
                formatted = 'Physiotherapist';
                break;
            case SpecialityType.CHIROPRACTOR:
                formatted = 'Chiropractor';
                break;
            case SpecialityType.CHIROPODIST:
                formatted = 'Chiropodist';
                break;
            case SpecialityType.DOCTOR:
                formatted = 'Doctor';
                break;
            case SpecialityType.OSTEOPATH:
                formatted = 'Osteopath';
                break;
            case SpecialityType.REHABILITATION_DOCTOR:
                formatted = 'Rehabilitation Doctor';
                break;
            case SpecialityType.SPORTS_MEDICINE_DOCTOR:
                formatted = 'Sports Medicine Doctor';
                break;
            case SpecialityType.ORTHOPEDIC_SURGEON:
                formatted = 'Orthopedic Surgeon';
                break;
            case SpecialityType.PODOLOGIST:
                formatted = 'Podologist';
                break;
            case SpecialityType.FITNESS_TRAINER:
                formatted = 'Fitness Trainer';
                break;
            case SpecialityType.SPORTS_COACH:
                formatted = 'Sports Coach';
                break;
            case SpecialityType.STUDENT:
                formatted = 'Student';
                break;
            case SpecialityType.OTHER:
                formatted = 'Other';
                break;
            default:
                formatted = '';
                break;
        }
        return formatted;
    }
}
