import {Injectable} from '@angular/core';
import {LogPublisher} from "./logPublisher";
import {ConsoleLogPublisher} from "./consoleLogPublisher";
import {LogLevel} from "./logLevel";
import {LogEntry} from "./logEntry";

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    public level: LogLevel;
    public readonly loggers: LogPublisher[];

    constructor() {
        this.loggers = [];
        this.level = LogLevel.ALL;

        this.register(new ConsoleLogPublisher());
    }

    public register(logPublisher: LogPublisher): void {
        this.loggers.push(logPublisher);
    }

    public debug(entry: LogEntry): void {
        if (this.level <= LogLevel.DEBUG) {
            for (const logger of this.loggers) {
                logger.debug(entry);
            }
        }
    }

    public info(entry: LogEntry): void {
        if (this.level <= LogLevel.INFO) {
            for (const logger of this.loggers) {
                logger.info(entry);
            }
        }
    }

    public warn(entry: LogEntry): void {
        if (this.level <= LogLevel.WARN) {
            for (const logger of this.loggers) {
                logger.warn(entry);
            }
        }
    }

    public error(entry: LogEntry): void {
        if (this.level <= LogLevel.ERROR) {
            for (const logger of this.loggers) {
                logger.error(entry);
            }
        }
    }

    public fatal(entry: LogEntry): void {
        if (this.level <= LogLevel.FATAL) {
            for (const logger of this.loggers) {
                logger.fatal(entry);
            }
        }
    }
}
