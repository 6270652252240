import {Injectable} from '@angular/core';
import {DEFAULT_GOOGLE_ANALYTICS_EVENT_NAMES} from './defaultGoogleAnalyticsTypes';

declare let gtag: Function; // Provided in googleAnalytics.*.js

/**
 * GUIDE:
 * https://support.google.com/analytics/answer/1033068#Anatomy&zippy=%2Cin-this-article
 */
@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor() {
    }

    /**
     * Switches to a new page. All subsequent event will be under that page
     */
    public switchPage(measurementId: string, title: string, url: string, path: string): void {
        let data = Object.create(null);
        if (title) {
            data['page_title'] = title;
        }
        if (url) {
            data['page_location'] = url;
        }
        if (path) {
            data['page_path'] = path;
        }

        gtag('config', measurementId, data);
    }

    public addEvent(eventName: DEFAULT_GOOGLE_ANALYTICS_EVENT_NAMES,
                    eventCategory: string,
                    eventAction: string,
                    eventLabel?: string,
                    eventValue?: string): void {
        gtag('event', eventName, {
            eventCategory: eventCategory, eventLabel: eventLabel, eventAction: eventAction, eventValue: eventValue
        });
    }

    public addCustomEvent(eventName: string,
                          eventCategory: string,
                          eventAction: string,
                          eventLabel?: string,
                          eventValue?: string): void {
        gtag('event', eventName, {
            eventCategory: eventCategory, eventLabel: eventLabel, eventAction: eventAction, eventValue: eventValue
        });
    }
}
