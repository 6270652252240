export class Stack {
    /**
     * Get calling method.
     * @param nestLevel 0 = Method that called this, 1 = Previous, 2 = Two steps up the stack, etc
     */
    public static getCallee(nestLevel: number): string {
        const stackItems = (new Error()).stack.split('\n');
        if (stackItems.length >= nestLevel + 1) {
            return stackItems[nestLevel + 1].split('@')[0];
        } else {
            return '';
        }
    }
}
