import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-text-search',
    templateUrl: './text-search.component.html',
    styleUrls: ['./text-search.component.scss']
})
export class TextSearchComponent implements OnInit {

    @Input()
    public value: string;

    @Input()
    public searchHint: string;

    @Input()
    public maxLength?: number;

    @Input()
    public minLength?: number;

    @Input()
    public type: 'text' | 'email' = 'text';

    @Output()
    public valueChange: EventEmitter<string>;

    @Output()
    public submitEvent: EventEmitter<void>;

    constructor() {
        this.searchHint = 'Search';
        this.valueChange = new EventEmitter<string>();
        this.submitEvent = new EventEmitter<void>();
    }

    ngOnInit(): void {
        if (this.value === undefined) {
            this.value = '';
        }
    }

    onValueChange(event) {
        if (event.keyCode === 13 /* enter */ && this.value.length >= this.minLength) {
            this.submitEvent.emit();
        }
        this.valueChange.emit(this.value);
    }

    clear() {
        this.value = '';
        this.valueChange.emit(this.value);
    }
}
