import { BaseDto } from "./base.dto";

export class AccountMetaDto extends BaseDto {
    public metaKey: string;
    public metaValue: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    public set Code(_code: string) {
        this.code = _code;
    }

    clone(): AccountMetaDto {
        const c = new AccountMetaDto();
        c.code = this.code;
        c.createdOn = this.createdOn;
        c.deleted = this.deleted;
        c.metaKey = this.metaKey;
        c.metaValue = this.metaValue;
        return c;
    }
}
