import {Injectable} from '@angular/core';
import {SubscriptionPlan} from '../../models/subscriptionPlan';
import {SubscriptionPlanType} from '../../models/subscriptionPlanType';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionPlanDaoService {

    constructor() {
    }

    public getAll(): SubscriptionPlan[] {
        const plans: SubscriptionPlan[] = [];
        plans.push(new SubscriptionPlan(SubscriptionPlanType.PRO, '66ae1790-92fd-46b7-bc09-f33b4e146db5', 'Pro'));
        plans.push(new SubscriptionPlan(SubscriptionPlanType.PRO_PLUS, '8112c5ef-700d-4aab-bff1-20f66316b4ed', 'Pro+'));
        plans.push(new SubscriptionPlan(SubscriptionPlanType.STARTER, 'cf270bff-fe9d-47b3-88ed-c396bb569fd9', 'Starter'));
        plans.push(new SubscriptionPlan(SubscriptionPlanType.PREMIUM, '652ba112-7b4b-4e0f-91ef-a7b89d690dd3', 'Premium'));
        plans.push(new SubscriptionPlan(SubscriptionPlanType.EXCELLENCE, '83599c90-a251-4740-b4f6-c2071e082e29', 'Excellence'));
        return plans;
    }

    public getForType(subscriptionPlanType: SubscriptionPlanType): SubscriptionPlan {
        return this.getAll().find(plan => plan.type === subscriptionPlanType);
    }
}
