export abstract class Dto {
    /**
     * Based on: https://nehalist.io/working-with-models-in-angular/
     */
    abstract deserialize(input: any): this;

    /**
     * Deserialize an array of Dtos into an array
     * @param arrayData the input data
     * @param dtoType the type of the Dto
     */
    protected deserializeArray<T extends Dto>(arrayData: any[], dtoType: { new(): T; }): T[] {
        const results: T[] = [];
        if (arrayData) {
            for (const arrayDatum of arrayData) {
                results.push(new dtoType().deserialize(arrayDatum));
            }
        }
        return results;
    }
}
