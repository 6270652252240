<img *ngIf="lazily; else nonLazily" class="image"
     [lazyLoad]="image"
     [defaultImage]="defaultImage"
     src="{{defaultImage}}"
     alt="{{description}}"
/>
<ng-template #nonLazily>
    <img class="image"
         src="{{image}}"
         alt="{{description}}"
    />
</ng-template>
<img #loadContainer class="d-none" src="{{image}}" alt=""/>
