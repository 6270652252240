<div class="component" *ngIf="userAccount">
    <div class="container d-flex flex-row pt-3 pb-3">
        <div class="flex-grow-1">
            {{copyrightNotice}}
        </div>
        <div class="flex-grow-1 d-flex flex-row justify-content-end">
            <div class="version">version {{systemVersion}}</div>
        </div>
    </div>
</div>
