import {Component, OnInit} from '@angular/core';
import {CommonBaseComponent} from "../common-base.component";

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends CommonBaseComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit(): void {
    }
}
