<div class="component container-fluid">
    <div class="container">
        <xng-breadcrumb [separator]="separator">
            <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first">
                <div class="d-flex flex-row align-items-center pt-3 pb-3">
                    <mat-icon *ngIf="first" class="me-1">home</mat-icon>
                    <ng-container>{{ breadcrumb }}</ng-container>
                </div>
            </ng-container>
        </xng-breadcrumb>
    </div>
</div>
<ng-template #separator>
    <mat-icon>arrow_right</mat-icon>
</ng-template>
