import {Injectable} from '@angular/core';
import {Dto} from "../../dtos/dto";

/**
 * Storage on the client side
 */
@Injectable({
    providedIn: 'root'
})
export class BrowserStorageService {

    constructor() {
    }

    public exists(key: string): boolean {
        return localStorage.hasOwnProperty(key);
    }

    public load<T>(key: string): T {
        let data: null | string = localStorage.getItem(key);
        let item: null | T = null;
        if (data !== null) {
            item = JSON.parse(data) as T;
        }
        return item;
    }

    public save(key: string, item: any): void {
        localStorage.setItem(key, JSON.stringify(item));
    }

    public saveDto(key: string, dto: Dto): void {
        this.save(key, dto);
    }

    public saveDtos(key: string, dtos: Dto[]): void {
        this.save(key, dtos);
    }

    public loadDto<TDto extends Dto>(key: string, dtoType: (new () => TDto)): TDto | null {
        let dtoData = this.load<TDto>(key);
        if (dtoData !== null) {
            dtoData = new dtoType().deserialize(dtoData);
        }
        return dtoData;
    }

    public loadDtos<TDto extends Dto>(key: string, dtoType: (new () => TDto)): TDto[] | null {
        let dtoData = this.load<TDto[]>(key);
        if (dtoData !== null && dtoData.length > 0) {
            const dtos: TDto[] = [];
            for (const datum of dtoData) {
                dtos.push(new dtoType().deserialize(datum))
            }
            dtoData = dtos;
        }
        return dtoData;
    }

    public deleteAll(): void {
        localStorage.clear();
    }
}
