<div class="outer d-flex mt-2 mb-2">
    <mat-icon class="search-icon me-2">search</mat-icon>
    <input matInput [type]="type"
           class="flex-grow-1"
           [(ngModel)]="value"
           placeholder="{{searchHint}}"
           [maxlength]="maxLength"
           (keyup)="onValueChange($event)"
           (ngModelChange)="onValueChange(value)"
    >
    <mat-icon class="close-button" matSuffix [class.visible]="value" [class.invisible]="!value" (click)="clear()">close</mat-icon>
</div>
