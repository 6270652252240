import {KeyValue as AngularKv} from "@angular/common";

/**
 * Shorthand for angular's interface
 */
export class KeyValue<K, V> implements AngularKv<K, V> {
    constructor(public readonly key: K, public readonly value: V) {
    }

    public static of<K, V>(key: K, value: V): KeyValue<K, V> {
        return new KeyValue<K, V>(key, value);
    }
}
