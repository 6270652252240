export enum DeviceType {
    UNKNOWN = "UNKNOWN",
    GRIP = "GRIP",
    MUSCLE_TESTER = "MUSCLE_TESTER",
    PLATES = "PLATES",
    SENS = "SENS",
    BUBBLE = "BUBBLE",
    LINK = "LINK",
    HEXAS = "HEXAS"
}

export class DeviceTypes {
    /**
     * Returns all except UNKNOWN
     */
    public static all(): DeviceType[] {
        return [DeviceType.GRIP, DeviceType.MUSCLE_TESTER, DeviceType.PLATES,
            DeviceType.SENS, DeviceType.BUBBLE, DeviceType.LINK, DeviceType.HEXAS];
    }

    public static parse(device: string): DeviceType {
        return DeviceType[device as keyof typeof DeviceType];
    }
}
