/**
 * Utilities about encoding/decoding
 * https://stackoverflow.com/a/7860443/245495
 */
export class Encoding {
    public static canBeBase64(data: string): boolean {
        try {
            window.atob(data);
        } catch (e) {
            return false;
        }
        return true;
    }
}
