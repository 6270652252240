export class UnitConverter {
    public static kgToLbs(kg: number): number {
        return kg * 2.20462262;
    }

    public static kgToNewton(kg: number): number {
        return kg * 9.8066500286389;
    }

    public static cmToInch(cm: number): number {
        return cm * 0.393700787;
    }

    public static degreeToRad(degree: number): number {
        return degree * (Math.PI / 180);
    }
}
