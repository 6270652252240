import {LogLevel} from "./logLevel";

export class LogEntry {
    public level: LogLevel;
    public date = new Date();
    public callee: string;
    public message: string;

    public static of(level: LogLevel, callee: string, message: string): LogEntry {
        const entry = new LogEntry();
        entry.level = level;
        entry.callee = callee;
        entry.message = message;
        return entry;
    }
}
