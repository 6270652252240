import {Injectable, OnInit} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

/**
 * Displays a notification to the user.
 */
@Injectable({
    providedIn: 'root'
})
export class NotificationService implements OnInit {
    private static readonly DEFAULT_DURATION_MS = 3000;

    constructor(private snackbar: MatSnackBar) {
    }

    ngOnInit(): void {
    }

    public info(message: string): void {
        this.snackbar.open(message, null, {duration: NotificationService.DEFAULT_DURATION_MS});
    }
}
