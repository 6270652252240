export enum SubscriptionPlanType {
    BASIC = 'BASIC',
    PRO = 'PRO',
    PRO_PLUS = 'PRO_PLUS',
    STARTER = 'STARTER',
    PREMIUM = 'PREMIUM',
    EXCELLENCE = 'EXCELLENCE'
}

export class SubscriptionPlanTypeUtils {

    public static parse(subscription: string): SubscriptionPlanType {
        return SubscriptionPlanType[subscription as keyof typeof SubscriptionPlanType];
    }

    public static getAll(): SubscriptionPlanType[] {
        return Object.values(SubscriptionPlanType);
    }

    public static title(subscription: SubscriptionPlanType): string {
        let title: string;
        switch (subscription) {
            case SubscriptionPlanType.BASIC:
                title = 'Basic';
                break;
            case SubscriptionPlanType.PRO:
                title = 'Pro';
                break;
            case SubscriptionPlanType.PRO_PLUS:
                title = 'Pro+';
                break;
            case SubscriptionPlanType.STARTER:
                title = 'Starter';
                break;
            case SubscriptionPlanType.PREMIUM:
                title = 'Premium';
                break;
            case SubscriptionPlanType.EXCELLENCE:
                title = 'Excellence';
                break;
            default:
                throw Error(`not existing type ${subscription}`)
        }
        return title;
    }
}
