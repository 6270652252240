import {Injectable} from '@angular/core';
import html2pdf from 'html2pdf.js'
import {Subject, timer} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class PdfService {

    constructor() {
    }

    /**
     * Generates and prompts for download as pdf the passed markup
     * REMARKS:
     * - Does not support SVG.
     * - The markup is cloned and any localized css are not available, use printStyles.scss if needed
     */
    public downloadPdf(markup: HTMLElement, filename: string, destroySubject: Subject<void>) {
        const options = {
            margin: [3, 0, 3, 0],
            filename: `${filename}.pdf`,
            image: {type: 'jpeg', quality: 0.75},
            html2canvas: {scale: 2, windowWidth: 1920},
            jsPDF: {format: 'A4', orientation: 'portrait', compress: true},
        };

        const waitToLoadMs = 250; // Some charts and components need time to init. Tests worked for 80ms, 250ms seems reasonable.

        timer(waitToLoadMs)
            .pipe(takeUntil(destroySubject))
            .subscribe(_avoid => {
                html2pdf()
                    .set(options)
                    .from(markup)
                    .outputPdf()
                    .save()
                    .catch(e => {
                        console.error('downloadPdf', e);
                    })
                    .catchExternal(e => {
                        console.log(`downloadPdf`, e);
                    });
            });
    }

}
