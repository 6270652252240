import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-kinvent-logo',
    templateUrl: './kinvent-logo.component.html',
    styleUrls: ['./kinvent-logo.component.scss']
})
export class KinventLogoComponent implements OnInit {

    @Input()
    public size: number;

    constructor() {
    }

    ngOnInit(): void {
    }

}
