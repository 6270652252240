import {Component, OnDestroy} from "@angular/core";
import {Subject} from "rxjs";
import {AnalyticsService} from "../services/analytics/analytics.service";
import {ServiceInjectorService} from "../services/service-injector.service";
import {NotificationService} from "../services/notification.service";
import {LoadingState} from "../viewmodels/loadingState";
import {ErrorHandlerService} from "../services/error-handler.service";

/**
 * Base component for all our component, where it makes sense. Simple ones might not need this.
 */
@Component({
    selector: 'app-base',
    template: '',
    styles: [''],
})
export class CommonBaseComponent implements OnDestroy {
    protected readonly destroySubject: Subject<void>;

    // Enums
    public readonly loadingStates = LoadingState;

    // Services
    protected readonly notification: NotificationService;
    protected readonly analytics: AnalyticsService;
    protected readonly errorHandler: ErrorHandlerService;

    public constructor() {
        this.destroySubject = new Subject<void>();

        this.analytics = ServiceInjectorService.injector.get(AnalyticsService);
        this.notification = ServiceInjectorService.injector.get(NotificationService);
        this.errorHandler = ServiceInjectorService.injector.get(ErrorHandlerService);
    }

    public ngOnDestroy(): void {
        this.destroySubject.next();
    }
}
