import {Observable} from "rxjs";

// @dynamic
// https://github.com/ng-packagr/ng-packagr/issues/696
export class RxUtils {
    /**
     * Creates a hot observable for the onLoad event of an image element
     */
    public static onImageLoad(image: HTMLImageElement) {
        return new Observable<HTMLImageElement>(subscriber => {
            image.onload = () => {
                subscriber.next(image);
                subscriber.complete();
            };
            image.onerror = (event: Event) => {
                subscriber.error(event);
            }
        })
    }
}
