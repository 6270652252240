import {format} from 'date-fns';

export enum DateFormat {
    TIME,
    DATE_SHORTER,
    DATE_SHORT,
    DATE_LONG,
    DATE_TIME_CONDENSED,
    DATE_TIME_SHORT,
    DATE_TIME_LONG,
}

// @dynamic
// https://github.com/ng-packagr/ng-packagr/issues/696
export class Dates {
    public static format(date: Date, formatType: DateFormat): string {
        return format(date, this.getFormat(formatType));
    }

    public static getFormat(formatType: DateFormat): string {
        let dateFormat;
        switch (formatType) {
            case DateFormat.TIME:
                dateFormat = 'HH:mm';
                break;
            case DateFormat.DATE_SHORTER:
                dateFormat = 'dd-MM-yy';
                break;
            case DateFormat.DATE_SHORT:
                dateFormat = 'dd-MM-yyyy';
                break;
            case DateFormat.DATE_LONG:
                dateFormat = 'dd MMM yyyy';
                break;
            case DateFormat.DATE_TIME_CONDENSED:
                dateFormat = 'ddMMMyy.HH.mm';
                break;
            case DateFormat.DATE_TIME_SHORT:
                dateFormat = 'dd-MM-yyyy · HH:mm';
                break;
            case DateFormat.DATE_TIME_LONG:
                dateFormat = 'dd MMM yyyy · HH:mm:ss';
                break;
        }
        return dateFormat;
    }
}
