export enum SubscriptionPeriodUnit {
    MONTHLY = "MONTHLY",
    ANNUAL = "ANNUAL",
    CUSTOM = "CUSTOM"
}

export class SubscriptionPeriodUnitUtils {

    public static parse(subscription: string): SubscriptionPeriodUnit {
        return SubscriptionPeriodUnit[subscription as keyof typeof SubscriptionPeriodUnit];
    }

    public static getAll(): SubscriptionPeriodUnit[] {
        return Object.values(SubscriptionPeriodUnit)
    }

    public static title(subscription: SubscriptionPeriodUnit): string {
        let title: string;
        switch (subscription) {
            case SubscriptionPeriodUnit.MONTHLY:
                title = 'Monthly';
                break;
            case SubscriptionPeriodUnit.ANNUAL:
                title = 'Annual';
                break;
            case SubscriptionPeriodUnit.CUSTOM:
                title = 'Custom';
                break;
            default:
                throw Error(`not existing type ${subscription}`)
        }
        return title;
    }
}
